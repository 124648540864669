<template>
    <div class="wrap">
        <!-- pc布局【支付成功】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <div class="pc-shop">
                    <div class="pc-title">{{$t('message.paysuccess')}}</div>
                    <div class="pc-txt">{{$t('message.m')}}{{orderAddObj.totalmoney}}</div>
                    <div class="pc-bor" @click="getQueryOrderInfo">{{$t('message.quyorderinfo')}}</div>
                    <div class="pc-tip">{{$t('message.orinfotip')}}</div>
                </div>
                <div class="pc-all">
                    <div class="pc-all-item">
                        <div class="pc-all-lab">{{$t('message.ordernum')}}：</div>
                        <div class="pc-all-text">{{orderAddObj.order_no}}</div>
                    </div>
                    <div class="pc-all-item">
                        <div class="pc-all-lab">{{$t('message.shoshopinfo')}}：</div>
                        <div class="pc-all-text">{{orderAddObj.user_name}}{{orderAddObj.mobile}}<br/>{{orderAddObj.info}}，{{orderAddObj.apartment}}，{{orderAddObj.doorbell}}，{{orderAddObj.postcode}}{{orderAddObj.city}}，{{orderAddObj.country}}</div>
                    </div>
                    <div class="pc-all-item">
                        <div class="pc-all-lab">{{$t('message.shopnames')}}：</div>
                        <div class="pc-all-text">
                            <div v-for="(item,index) in orderAddObj.goods" :key="index">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                            </div>
                        </div>
                    </div>
                    <div class="pc-all-item">
                        <div class="pc-all-lab">{{$t('message.paytype')}}：</div>
                        <div class="pc-all-text">
                            {{orderAddObj.price_type==1?$t('message.wchartpay'):''}}
                            {{orderAddObj.price_type==2?$t('message.zfbpay'):''}}
                            {{orderAddObj.price_type==3?$t('message.xykpay'):''}}
                            {{orderAddObj.price_type==4?$t('message.xxpay'):''}}
                            /{{orderAddObj.type2==1?$t('message.zxpay'):$t('message.hdfkpay')}}
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【支付成功】 -->
        <b-container class="wap-shopcar">
            <b-row>
                <div class="wap-shop-pay">
                    <div class="wap-title">{{$t('message.paysuccess')}}</div>
                    <div class="wap-txt">{{$t('message.m')}}{{orderAddObj.totalmoney}}</div>
                    <div class="wap-bor" @click="getQueryOrderInfo">{{$t('message.quyorderinfo')}}</div>
                    <div class="wap-tip">{{$t('message.orinfotip')}}</div>
                </div>
            </b-row>
            <b-row>
                <div class="wap-shop-all">
                    <div class="wap-all-item">
                        <div class="wap-all-lab">{{$t('message.ordernum')}}：</div>
                        <div class="wap-all-text">{{orderAddObj.order_no}}</div>
                    </div>
                    <div class="wap-all-item">
                        <div class="wap-all-lab">{{$t('message.shoshopinfo')}}：</div>
                        <div class="wap-all-text">{{orderAddObj.user_name}}{{orderAddObj.mobile}}<br/>{{orderAddObj.info}}，{{orderAddObj.apartment}}，{{orderAddObj.doorbell}}，{{orderAddObj.postcode}}{{orderAddObj.city}}，{{orderAddObj.country}}</div>
                    </div>
                    <div class="wap-all-item">
                        <div class="wap-all-lab">{{$t('message.shopnames')}}：</div>
                        <div class="wap-all-text">
                            <div v-for="(item,index) in orderAddObj.goods" :key="index">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                            </div>
                        </div>
                    </div>
                    <div class="wap-all-item">
                        <div class="wap-all-lab">{{$t('message.paytype')}}：</div>
                        <div class="wap-all-text">
                            {{orderAddObj.price_type==1?$t('message.wchartpay'):''}}
                            {{orderAddObj.price_type==2?$t('message.zfbpay'):''}}
                            {{orderAddObj.price_type==3?$t('message.xykpay'):''}}
                            {{orderAddObj.price_type==4?$t('message.xxpay'):''}}
                            /{{orderAddObj.type2==1?$t('message.zxpay'):$t('message.hdfkpay')}}
                        </div>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'paysuccess',
    data() {
        return {
            languageName:'',
            orderAddObj:{}
        }
    },
    methods: {
        // 去查看订单详情
        getQueryOrderInfo(){
            if(this.orderAddObj.order_no){
                this.$router.push({path:'/myorderinfo',query:{order_no:this.orderAddObj.order_no}});
            }
        },
        // 获取订单详情
        getOrderInfo(token,orderno){
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:orderno
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.orderAddObj = res.data;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        getEachPayInfo(token,orderno){
            this.$http.api_order_orderquery({
                orderno:orderno,//（订单编号）、
            }).then((res)=>{
                if(res){
                    // code：1已支付、0未支付
                    if(res.code==1){
                        if(res.data){
                            this.getOrderInfo(token,orderno);// 获取订单详情
                        }
                    }else if(res.code==0){
                        if(res.data){
                            this.$router.push({path:'/myorderinfo',query:{order_no:orderno}});
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            let routes = this.$route.query;//地址栏参数
            if(routes&&routes.orderno){
                this.getEachPayInfo(token,routes.orderno);//查询支付状态
            }
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        .pc-shop{
            width: 40%;
            background: #1f73b7;
            padding:40px 20px 80px;
            text-align: center;
            .pc-title{
                font-size:28px;
                padding:20px 0;
                color: #fff;
            }
            .pc-txt{
                font-size:28px;
                padding:20px 0;
                font-weight: bold;
                color: #fff;
            }
            .pc-bor{
                cursor: pointer;
                display: inline-block;
                padding:8px 40px;
                border:1px solid #fff;
                color: #fff;
                margin: 10px 0 20px 0;
            }
            .pc-tip{
                color: #fff;
            }
            
        }
        .pc-all{
            width: 60%;
            padding:30px 60px 40px;
            background: #f4f7fe;
            color: #6e6e74;
            .pc-all-item{
                display: flex;
                -webkit-box-orient: horizontal;
                margin: 10px 0;
                .pc-all-lab{
                    line-height: 26px;
                }
                .pc-all-text{
                    line-height: 26px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            padding:10px 25px;
            .row{padding: 0;margin: 0;}
            .wap-shop-pay{
                width: 100%;
                background: #1f73b7;
                padding:20px 20px 30px;
                text-align: center;
                .wap-title{
                    font-size:24px;
                    padding:20px 0;
                    color: #fff;
                }
                .wap-txt{
                    font-size:24px;
                    padding:20px 0;
                    font-weight: bold;
                    color: #fff;
                }
                .wap-bor{
                    cursor: pointer;
                    display: inline-block;
                    
                    padding:8px 40px;
                    border:1px solid #fff;
                    color: #fff;
                    margin: 10px 0;
                }
                .wap-tip{
                    
                    color: #fff;
                }
            }
            .wap-shop-all{
                width: 100%;
                padding:10px 0px 20px;
                color: #6e6e74;
                .wap-all-item{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    margin: 10px 0;
                    .wap-all-lab{
                        width: 20%;
                        
                        line-height: 26px;
                        color: #95959e;
                    }
                    .wap-all-text{
                        width: 80%;
                        
                        line-height: 26px;
                        color: #95959e;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        background: #f4f7fe;
        .pc-shopcar{display: none;}
        .wap-shopcar{
            padding:10px 25px;
            margin: 0;
            .wap-shop-pay{
                width: 100%;
                background: #1f73b7;
                padding:20px 20px 30px;
                text-align: center;
                .wap-title{
                    font-size:24px;
                    padding:20px 0;
                    color: #fff;
                }
                .wap-txt{
                    font-size:24px;
                    padding:20px 0;
                    font-weight: bold;
                    color: #fff;
                }
                .wap-bor{
                    cursor: pointer;
                    display: inline-block;
                    
                    padding:8px 40px;
                    border:1px solid #fff;
                    color: #fff;
                    margin: 10px 0;
                }
                .wap-tip{
                    
                    color: #fff;
                }
            }
            .wap-shop-all{
                width: 100%;
                padding:10px 0px 20px;
                color: #6e6e74;
                .wap-all-item{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    margin: 10px 0;
                    .wap-all-lab{
                        width: 20%;
                        
                        line-height: 26px;
                        color: #95959e;
                    }
                    .wap-all-text{
                        width: 80%;
                        
                        line-height: 26px;
                        color: #95959e;
                    }
                }
            }
        }
    }
}
</style>